import { render, staticRenderFns } from "./_.vue?vue&type=template&id=3afaa78a&scoped=true&"
import script from "./_.vue?vue&type=script&lang=js&"
export * from "./_.vue?vue&type=script&lang=js&"
import style0 from "./_.vue?vue&type=style&index=0&id=3afaa78a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3afaa78a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NuxtDynamic: require('E:/JenkinsPyWorkspace/Jobs/IntegrationsDeploy/Projects/CPv3/MultiSiteClient/node_modules/@blokwise/dynamic/src/components/Dynamic.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VIcon})
